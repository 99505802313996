import React, {useState, useRef} from 'react';
import {useFeedback} from '@lib/feedbackContext';
import {useEmailResetPasswordUrlMutation} from '@graphql/generated/graphql';
import useOutsideClick from '@lib/useOutsideClick';

interface IForgotPasswordModalProps {
  onClose: () => void;
}

const ForgotPasswordModal: React.FC<IForgotPasswordModalProps> = ({onClose}) => {
  const {enqueueFeedback} = useFeedback();

  const ref = useRef();
  useOutsideClick(ref, onClose);

  const [email, setEmail] = useState<string>('');

  const [emailResetPasswordUrl] = useEmailResetPasswordUrlMutation();
  const handleSubmit = async () => {
    const {
      data: {
        emailResetPasswordUrl: {success, errors},
      },
    } = await emailResetPasswordUrl({variables: {email}});
    if (success) {
      enqueueFeedback('Email sent');
      onClose();
    } else enqueueFeedback(errors[0].detail, true);
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div ref={ref} className="relative max-w-md w-full bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div className="absolute top-2.5 right-2 cursor-pointer" onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-8 w-8 text-indigo-600">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>

        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 mb-8">Forgot password</h2>

        <div className="sm:col-span-4 mb-8">
          <label className="block text-lg font-medium text-gray-700 mb-2">Email</label>
          <div className="flex items-center max-w-lg w-full mt-1">
            <input
              type="text"
              name="email"
              autoComplete="email"
              placeholder="johndoe@gmail.com"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              onChange={e => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div>
          <button
            type="button"
            className="mt-6 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={handleSubmit}
          >
            Email Reset Link
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
